a.tip {
    /* border-bottom: 1px dashed; */ 
    text-decoration: none
}
a.tip:hover {
    cursor: help;
    position: relative
}
a.tip span {
    display: none
}
a.tip:hover span {
        /* border: #c0c0c0 1px dotted; */
        padding: 5px;
        display: block;
        z-index: 10000 !important;
        background: #008fb5;
        box-shadow: darkblue;
        left: 103px;
        margin: 10px;
        /* max-width: 250px;
        min-width: 250px; */
        /* width: 250px !important; */
        /* min-width: 116px !important; */
        width: 116px !important;
        max-width: 250px !important;
        min-height: 20px !important;
        max-height: 50px !important;
        word-break: break-word !important;
        position: absolute;
        top: -20px;
        /* text-decoration: none; */
        color: #FFF;
        /* border-radius: 6px; */
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
        font-size: 11px;
        border-top-right-radius: 7px;
        /* border-top-left-radius: 7px; */
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 7px;
        overflow-y: hidden;
}