
.customMBottom{
    margin-bottom: 15px !important;
}

.tableCol{
    font-weight: 500;
    font-size: 13px !important;
}

.theadBackground{
    background: #bacd !important;
}

.customOperatorButton{
    width: max-content;
}

.custompadd{
    padding:"5px";
}